<template>
  <div class="map-page-box">
    <baidu-map class="map" @ready="handler">
      <bm-marker :position="position" :dragging="true" @click="infoWindowOpen">
        <bm-info-window
          :show="show"
          @close="infoWindowClose"
          @open="infoWindowOpen"
        >
          <p style="color: #333; margin-bottom: 8px; font-size: 14px">
            {{ cmpName }}
          </p>
          <span
            style="color: #999; margin-bottom: 8px; font-size: 14px"
          >{{ cmpAddress }}</span>
        </bm-info-window>
      </bm-marker>
    </baidu-map>
  </div>
</template>

<script>
export default {
  props: {
    cmpName: {
      default: '',
      type: String
    },
    cmpAddress: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      show: true,
      position: {}
    }
  },
  methods: {
    // 地图的操作
    infoWindowClose() {
      this.show = false
    },
    // 地图的操作
    infoWindowOpen() {
      this.show = true
    }, // 地图初始化
    handler({ BMap, map }) {
      const myGeo = new BMap.Geocoder()
      myGeo.getPoint(this.cmpAddress, (point) => {
        if (point) {
          map.centerAndZoom(point, 19)
          this.position = { ...point }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.map-page-box {
  height: 100%;
  width: 100%;
  .map{
    height: 100%;
    width: 100%;
  }
  /deep/.BMap_cpyCtrl,
  /deep/.BMap_noprint,
  /deep/.anchorBL {
    display: none !important;
  }
}
</style>
